import React, { useState } from 'react';
import { StepHeader, IStepProps } from './step-header';
import { Payment } from './payment/payment';
import { PlaceOrder } from './place-order/place-order';
import { Membership } from './payment/membership';

export const PaymentStep = (step: IStepProps) => {
    const {
        stepNumber,
        open,
        complete,
        disabled,
    } = step;
    const [beforePlaceOrder, setBeforePlaceOrder] =
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        useState(() => {});
    return (
        <>
            <StepHeader stepNumber={stepNumber} stepName="Payment" isComplete={complete} open={open} disabled={disabled}>
                <Payment setBeforePlaceOrder={setBeforePlaceOrder} />
            </StepHeader>
            <Membership />
            <PlaceOrder beforePlaceOrder={beforePlaceOrder} />
        </>
    );
};
PaymentStep.stepKey = 'payment';
