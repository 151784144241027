import React, { useEffect, useState } from 'react';
import { withMask } from 'use-mask-input';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import fStyles from 'ui/component/checkout/styles/form-style.css';
import { AcceptedCards } from './accepted-cards';
import { GetCreditCartType } from './get-credit-card-type';
import styles from './style.css';
import {
    CREDIT_CARD_ERRORS as CC_ERRORS,
    validateCardNumber,
    validateExpiryDate,
    validateCvv,
    validateCardHolderName,
} from '../../util/validation';

export interface ICreditCardInfo {
    cardType: string;
    cardHolderName: string;
    cardNumber: string;
    expiryMonth: string;
    expiryYear: string;
    cvv: string;
    isActivePaymentTokenEnabler: boolean;
}

interface ICreditCardFormProps {
    formId: string;
    formTitle?: string;
    onCreditCardInfoChange: (info: ICreditCardInfo) => void;
}

interface ISetter {
    (value: string): void;
}

export const CreditCardForm = ({ onCreditCardInfoChange, formId, formTitle }: ICreditCardFormProps) => {
    const t = usePhraseTranslater();
    const [cardType, setCardType] = useState('');
    const [cardHolderName, setCardHolderName] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [expiryMonth, setExpiryMonth] = useState('');
    const [expiryYear, setExpiryYear] = useState('');
    const [cvv, setCvv] = useState('');
    const [isActivePaymentTokenEnabler, setIsActivePaymentTokenEnabler] = useState(false);

    const [cardNumberError, setCardNumberError] = useState('');
    const [expiryDateError, setExpiryDateError] = useState('');
    const [cvvError, setCvvError] = useState('');
    const [cardHolderNameError, setCardHolderNameError] = useState('');

    const ccErrors: Record<string, string> = {
        [CC_ERRORS.HOLDER_NAME]: t('Invalid card holder name'),
        [CC_ERRORS.NUMBER]: t('Invalid card number'),
        [CC_ERRORS.EXPIRY_DATE_WRONG_FORMAT]: t('Invalid date format'),
        [CC_ERRORS.EXPIRY_DATE_EXPIRED]: t('Card has expired'),
        [CC_ERRORS.CVV]: t('Invalid CVV'),
    };

    const getCCErrorText = (errorType: string) => ccErrors[errorType] ?? '';

    const setSeparatedDateFields = (date: string): void => {
        const month = date.split('/')[0] ?? '';
        const year = date.split('/')[1] ?? '';
        setExpiryMonth(month);
        setExpiryYear(`20${year}`);
    };
    const validateCardNumberAndSetType = (cardNumber: string): void => {
        const ccType = GetCreditCartType(cardNumber);
        setCardType(ccType.cardTypeCode);
        if (!ccType.isSupported) {
            setCardNumberError(t('Credit card type "%1" is not supported!', ccType.cardTypeLabel));
        }
    };

    const handleInputChange = (
        setter: ISetter,
        errorSetter: (error: string) => void,
        validator: (value: string) => string,
        isDate?: boolean,
    ) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setter(value);
        const errorType: string = validator(value);
        const errorText: string = getCCErrorText(errorType);
        errorSetter(errorText);
        if (isDate && errorText === '') {
            setSeparatedDateFields(value);
        }
    };

    const getClassName = (value: string, error: string) => {
        if (value === '') {
            return styles.inputInitial;
        }

        return error ? styles.inputInvalid : styles.inputValid;
    };

    useEffect(() => {
        validateCardNumberAndSetType(cardNumber);
        onCreditCardInfoChange({
            cardType,
            cardHolderName,
            cardNumber,
            expiryMonth,
            expiryYear,
            cvv,
            isActivePaymentTokenEnabler,
        });
    }, [cardType, cardHolderName, cardNumber, expiryMonth, expiryYear, cvv, isActivePaymentTokenEnabler]);

    return (
        <form id={formId}>
            {formTitle && (
                <div className={classes(fStyles.formHeader)}>
                    <div className={fStyles.formNormalTitle}>{t(formTitle)}</div>
                </div>
            )}
            <input
                id="cardType"
                className={styles.cardType}
                type="hidden"
                value={cardType}
                name="cardType"
            />
            <div
                className={classes({
                    [fStyles.formField]: true,
                    [fStyles.formFieldError]: cardHolderNameError,
                })}
            >
                <label htmlFor="cardHolderName">{t('Name')}</label>
                <input
                    id="cardHolderName"
                    className={`${styles.creditCardInput} ${getClassName(cardHolderName, cardHolderNameError)}`}
                    type="text"
                    value={cardHolderName}
                    name="cardHolderName"
                    onChange={handleInputChange(setCardHolderName, setCardHolderNameError, validateCardHolderName)}
                />
                {cardHolderNameError && <span className={styles.error}>{cardHolderNameError}</span>}
            </div>
            <div
                className={classes({
                    [fStyles.formField]: true,
                    [fStyles.formFieldError]: cardNumberError,
                })}
            >
                <label htmlFor="cardNumber">{t('Card Number')}</label>
                <input
                    ref={withMask('9999999999999[9][9][9][9][9][9]')}
                    id="cardNumber"
                    className={`${styles.creditCardInput} ${getClassName(cardNumber, cardNumberError)}`}
                    type="text"
                    value={cardNumber}
                    name="cardNumber"
                    onChange={handleInputChange(setCardNumber, setCardNumberError, validateCardNumber)}
                />
                {cardNumberError && <span className={styles.error}>{cardNumberError}</span>}
            </div>
            <div className={styles.extraRowWrapper}>
                <div
                    className={classes({
                        [fStyles.formField]: true,
                        [fStyles.formFieldError]: expiryDateError,
                    })}
                >
                    <label htmlFor="expiryDate">{t('Expiration')}</label>
                    <input
                        ref={withMask('99/99')}
                        id="expiryDate"
                        className={`${styles.creditCardInput} ${getClassName(expiryDate, expiryDateError)}`}
                        type="text"
                        value={expiryDate}
                        name="expiryDate"
                        onChange={handleInputChange(setExpiryDate, setExpiryDateError, validateExpiryDate, true)}
                        placeholder="MM/YY"
                    />
                    {expiryDateError && <span className={styles.error}>{expiryDateError}</span>}
                </div>
                <div
                    className={classes({
                        [fStyles.formField]: true,
                        [fStyles.formFieldError]: cvvError,
                    })}
                >
                    <label htmlFor="cvv">{t('Code')}</label>
                    <input
                        ref={withMask('999[9]')}
                        id="cvv"
                        className={`${styles.creditCardInput} ${getClassName(cvv, cvvError)}`}
                        type="text"
                        value={cvv}
                        name="cvv"
                        onChange={handleInputChange(setCvv, setCvvError, validateCvv)}
                    />
                    {cvvError && <span className={styles.error}>{cvvError}</span>}
                </div>
            </div>
            {formId === 'repay' && (
                <div className={styles.extraRowWrapper}>
                    <div className={styles.checkboxContainer}>
                        <label htmlFor="savePaymentDetails" className={styles.checkboxLabel}>
                            <input
                                type="checkbox"
                                id="savePaymentDetails"
                                checked={isActivePaymentTokenEnabler}
                                onChange={(e) => {
                                    setIsActivePaymentTokenEnabler(e.target.checked);
                                }}
                                className={styles.checkbox}
                            />
                            <span className={styles.checkboxText}>
                                {t('Save payment information for future purchases')}
                            </span>
                        </label>
                    </div>
                </div>
            )}
            <AcceptedCards />
        </form>
    );
};
