import { gql, TypedDocumentNode } from '@apollo/client';
import { CART_FIELDS } from './fragments';

export const SET_SHIPPING_ADDRESS: TypedDocumentNode = gql`
    ${CART_FIELDS}
    mutation SET_SHIPPING_ADDRESS(
        $cartId: String!
        $firstname: String!
        $lastname: String!
        $company: String
        $street: [String]!
        $city: String!
        $region: String!
        $postcode: String!
        $countryCode: String!
        $telephone: String!
        $saveInAddressBook: Boolean
    ) {
      setShippingAddressesOnCart(
        input: {
          cart_id: $cartId
          shipping_addresses: [
            {
              address: {
                firstname: $firstname
                lastname: $lastname
                company: $company
                street: $street
                city: $city
                region: $region
                postcode: $postcode
                country_code: $countryCode
                telephone: $telephone
                save_in_address_book: $saveInAddressBook
              }
            }
          ]
        }
      ) {
        cart {
            ...CartFields
        }
      }
    }
`;
