import React, { useEffect, useState } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { classes } from '@silkpwa/module/util/classes';
import { isEmpty } from 'lodash';
import { AddressForm } from 'ui/component/checkout/components/address-form/address-form';
import { AddressSummary } from 'ui/component/checkout/components/address-form/address-summary';
import fStyles from 'ui/component/checkout/styles/form-style.css';
import {
    cartBillingAddressVar,
    cartIdVar,
    cartVar,
    cartShippingAddressVar,
    defaultBillingAddressVar,
    getInitialAddressState,
    ICustomerAddress,
    isBillingAddressSetVar,
    isShippingAddressSetVar,
    sameAddressVar,
    stepsCompletedVar,
    emptyAddress,
    IAddress,
} from 'ui/page/checkout-page/checkout-state';
import { SET_BILLING_ADDRESS } from 'graphql/cart/billing-address';
import { isSameBillingAndShippingAddress } from 'ui/component/checkout/util/is-same-billing-and-shipping-address';
import { StandardLoadingImage } from 'ui/component/loading-image';
import { usePhraseTranslater } from '@silkpwa/module/i18n';

interface IBillingAddressFormProps {
    isComplete: boolean;
}

export const BillingAddressForm: React.FC<IBillingAddressFormProps> = ({ isComplete }) => {
    const t = usePhraseTranslater();
    const stepsCompleted = useReactiveVar(stepsCompletedVar);
    const cartId = useReactiveVar(cartIdVar);
    const cart = useReactiveVar(cartVar);
    const isBillingAddressSet = useReactiveVar(isBillingAddressSetVar);
    const cartBillingAddress = useReactiveVar(cartBillingAddressVar);
    const defaultBillingAddress = useReactiveVar(defaultBillingAddressVar);
    const isShippingAddressSet = useReactiveVar(isShippingAddressSetVar);
    const cartShippingAddress = useReactiveVar(cartShippingAddressVar);
    const sameAddress = useReactiveVar(sameAddressVar);
    const initialAddress =
        isBillingAddressSet ? getInitialAddressState(cartBillingAddress)
            : getInitialAddressState(cartShippingAddress);
    const [billingAddress, setBillingAddress] = useState(initialAddress);
    const [saveBillingAddress, { loading }] = useMutation(SET_BILLING_ADDRESS);
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        saveBillingAddress({
            variables: {
                cartId,
                ...billingAddress,
                sameAsShipping: (cart?.is_virtual ? null : sameAddress),
            },
        }).then(() => {
            isBillingAddressSetVar(true);
            stepsCompletedVar({ ...stepsCompleted, billing: true });
            cartBillingAddressVar({ // TODO This is a bit clunky and should be refactored
                ...billingAddress,
                id: '',
                country: { code: billingAddress.countryCode },
                country_code: billingAddress.countryCode,
                region:
                    {
                        code: billingAddress.region,
                        region_code: billingAddress.region,
                        region: billingAddress.region,
                    },
            });
        });
    };
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setBillingAddress({ ...billingAddress, [event.target.name]: value });
    };
    const handleSameAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            sameAddressVar(true);
            if (cartShippingAddress) {
                const newBillingAddress = {
                    ...getInitialAddressState(cartShippingAddress),
                    street:
                        Array.isArray(cartShippingAddress.street)
                            ? cartShippingAddress.street : [cartShippingAddress.street],
                };
                setBillingAddress(newBillingAddress);
                saveBillingAddress({
                    variables: {
                        cartId,
                        ...newBillingAddress,
                        sameAsShipping: true,
                    },
                }).then(() => {
                    isBillingAddressSetVar(true);
                    stepsCompletedVar({ ...stepsCompleted, billing: true });
                });
            }
        } else {
            sameAddressVar(false);
            setBillingAddress({ ...getInitialAddressState(defaultBillingAddress) });
            isBillingAddressSetVar(false);
        }
    };
    const isEmptyAddress = (address: ICustomerAddress|IAddress) => (
        (Array.isArray(address.street) && isEmpty(address?.street[0])) || isEmpty(address?.street)
    );

    const [stepCompleteLoader, setStepCompleteLoader] = useState(false);
    const handleStepComplete = () => {
        setStepCompleteLoader(true);
        if (!isEmptyAddress(cartBillingAddress)) {
            stepsCompletedVar({ ...stepsCompleted, billing: true });
        }
        setStepCompleteLoader(false);
    };

    useEffect(() => {
        if (isEmptyAddress(cartBillingAddress)) {
            let address = getInitialAddressState(emptyAddress);
            let isSameAsShipping = false;
            if (sameAddress) {
                address = {
                    ...getInitialAddressState(cartShippingAddress),
                    street:
                        Array.isArray(cartShippingAddress.street)
                            ? cartShippingAddress.street : [cartShippingAddress.street],
                };
                isSameAsShipping = true;
            } else if (!isEmptyAddress(defaultBillingAddress)) {
                // set default billing address as billing address
                address = { ...getInitialAddressState(defaultBillingAddress) };
                isSameAsShipping = isSameBillingAndShippingAddress(defaultBillingAddress, cartShippingAddress);
            }
            if (!isEmptyAddress(address)) {
                saveBillingAddress({
                    variables: {
                        cartId,
                        ...address,
                        sameAsShipping: isSameAsShipping,
                    },
                }).then(() => {
                    isBillingAddressSetVar(true);
                    sameAddressVar(isSameAsShipping);
                    // @ts-ignore
                    cartBillingAddressVar(address);
                    // @ts-ignore
                    setBillingAddress(address);
                });
            }
        }
    }, [sameAddress, defaultBillingAddress]);

    return (
        <>
            { !cart?.is_virtual && (
                <form>
                    <div className={classes(fStyles.formField, fStyles.inline, fStyles.center)}>
                        <input
                            id="sameAsShipping"
                            type="checkbox"
                            name="sameAsShipping"
                            onChange={handleSameAddress}
                            disabled={!isShippingAddressSet}
                            checked={sameAddress}
                        />
                        <label htmlFor="sameAsShipping" className={fStyles.normal}>
                            Same Billing & Shipping Address
                        </label>
                    </div>
                </form>
            )}
            {loading && (
                <div style={{ position: 'relative', padding: '2rem' }}>
                    <StandardLoadingImage />
                </div>
            )}
            {!loading && isBillingAddressSet && (
                <>
                    <AddressSummary
                        address={billingAddress}
                        title="Billing Address"
                        addressIsSetVar={(value: boolean) => {
                            sameAddressVar(false);
                            isBillingAddressSetVar(value);
                        }}
                    />
                    {!isComplete && (
                        <div className={classes(fStyles.formField, fStyles.actionButton)}>
                            <button
                                type="submit"
                                className={fStyles.checkoutButton}
                                onClick={handleStepComplete}
                                data-test="billing-submit"
                            >
                                {t('Save And Continue')}
                            </button>
                        </div>
                    )}
                    {stepCompleteLoader && <StandardLoadingImage />}
                </>
            )}
            {!loading && !isBillingAddressSet && (
                <AddressForm
                    address={billingAddress}
                    name="billing"
                    handleSubmit={handleSubmit}
                    onChange={onChange}
                    loading={loading}
                    setAddress={setBillingAddress}
                    disableInputs={(sameAddress && !cart?.is_virtual)}
                />
            )}
        </>
    );
};
