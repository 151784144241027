import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { IAvailablePaymentMethod } from 'ui/page/checkout-page/checkout-state';
import { Placeholder } from '../placeholder/placeholder';
import { ApplePay } from './buttons-content/apple-pay';
import { Braintree } from './buttons-content/braintree';
import { CreditCard } from './buttons-content/credit-card';
import { CreditCardVault } from './buttons-content/credit-card-vault';
import { CreditKey } from './buttons-content/credit-key';
import { PaypalExpress } from './buttons-content/paypal-express';
import { Terms } from './buttons-content/terms';
import { getActualMethodCode } from './payment-options';
import styles from './style.css';

interface IOptionProps {
    method: IAvailablePaymentMethod;
    selectedMethodCode: string;
    onClickPaymentOption: (method: IAvailablePaymentMethod) => void;
}

const paymentButtonComponents: { [key: string]: React.ElementType } = {
    purchaseorder: Placeholder,
    checkmo: Terms,
    checkmo_international: Terms,
    free: Placeholder,
    authorizenet_directpost: CreditCard,
    paypal_express: PaypalExpress,
    repay: CreditCard,
    repay_cc_vault: CreditCardVault,
    braintree: Braintree,
    chefworksbambora: CreditCard,
    creditkey_gateway: CreditKey,
    /* TODO: check if the payment method keys are correct */
    apple_pay: ApplePay,
};

const getMethodComponentByCode = (
    code: string,
): React.ElementType => paymentButtonComponents[getActualMethodCode(code)];

// TODO: Needs to refactor this file
export const Option: React.FC<IOptionProps> = ({ method, selectedMethodCode, onClickPaymentOption }) => {
    const t = usePhraseTranslater();
    return (
        <button
            id={method.code}
            type="button"
            onClick={() => onClickPaymentOption(method)}
            className={classes({
                [styles.action]: true,
                [styles.selectedMethod]: method.code === selectedMethodCode,
            }, styles.paymentOptionButton)}
        >
            {
                (getMethodComponentByCode(method.code)
                    ? React.createElement(getMethodComponentByCode(method.code), method)
                    : t(method.title))
            }
        </button>
    );
};
