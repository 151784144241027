import React, { useState, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import fStyles from 'ui/component/checkout/styles/form-style.css';
import { classes } from '@silkpwa/module/util/classes';
import {
    customerVar,
    ICustomerAddress,
    emptyAddress,
    getInitialAddressState,
} from 'ui/page/checkout-page/checkout-state';
import { AddressForm, IAddress } from './address-form';
import { AddressBookAddress } from './address-book/address-book-address';
import styles from './address-book/style.css';

interface IAddressBookProps {
    address: IAddress;
    name: string;
    handleSubmit: any;
    onChange: any;
    loading: boolean;
    setAddress: any;
    isShippingAddressSet: any;
}

export const AddressBook: React.FC<IAddressBookProps> = ({
    address,
    name,
    handleSubmit,
    onChange,
    loading,
    setAddress,
    isShippingAddressSet,
}) => {
    const t = usePhraseTranslater();
    const customer = useReactiveVar(customerVar);
    const customerAddressBook = customer?.addresses || [];
    const [selectedAddress, setSelectedAddress] = useState<ICustomerAddress | null>(null);
    const [showAddressForm, setShowAddressForm] = useState(false);
    const [filterText, setFilterText] = useState('');
    const preselectAddress = customerAddressBook.length > 0 ? customerAddressBook[0] : null;
    const filteredAddressBook = customerAddressBook.filter(
        (address: ICustomerAddress) => address.city.toLowerCase().includes(filterText.toLowerCase()) ||
            address.postcode.toLowerCase().includes(filterText.toLowerCase()),
    );
    const handleShipToAddress = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (selectedAddress) {
            event.preventDefault();
            setAddress({ ...getInitialAddressState(selectedAddress), saveInAddressBook: false });
        }
    };

    useEffect(() => {
        if (selectedAddress && !showAddressForm) {
            handleSubmit();
        }
    }, [address]);
    useEffect(() => {
        setShowAddressForm(customerAddressBook.length === 0);
    }, [customerAddressBook]);
    const resetAddress = () => {
        setAddress(emptyAddress);
    };
    const goBack = () => {
        setAddress(address);
        handleSubmit();
        setShowAddressForm(false);
        isShippingAddressSet(true);
    };
    const allowSave = !!customer; // Guests cannot save addresses to address book
    return (
        <>
            { !showAddressForm && (customerAddressBook.length > 0) && (
                <form>
                    <div className={styles.addressBookHeader}>
                        <button type="button" className={styles.backButton} onClick={() => goBack()}>
                            <i className="fa-solid fa-chevron-left" />
                        </button>
                        <div className={styles.addressBookTitle}>Your Address Book</div>
                    </div>
                    {address && (
                        <div className={styles.addressBookWrapper}>
                            <div className={styles.address}>
                                {address.company && (
                                    <div className={styles.title}>{address.company}</div>
                                )}
                                <div>{`${address.firstname} ${address.lastname}`}</div>
                                <div>{address.street}</div>
                                <div>{`${address.city} ${address.region} ${address.postcode}`}</div>
                                <div>{address.countryCode}</div>
                            </div>
                            <div className={styles.action}>
                                <button
                                    type="button"
                                    className={fStyles.buttonLink}
                                    onClick={() => setShowAddressForm(true)}
                                >
                                    {t('Edit')}
                                </button>
                            </div>
                        </div>
                    )}
                    <div className={styles.searchHeading}>
                        <div>
                            Select a different address
                        </div>
                        <div className={styles.addressCount}>
                            {` (${filteredAddressBook.length} / ${customerAddressBook.length})`}
                        </div>
                    </div>
                    <div className={classes(fStyles.formField, styles.searchInput)}>
                        <input
                            type="text"
                            placeholder="Search City or Zip"
                            value={filterText}
                            onChange={e => setFilterText(e.target.value)}
                        />
                        <i className="fa-solid fa-magnifying-glass-plus" />
                    </div>
                    <div data-test="address-items" className={styles.addressScroller}>
                        {(filteredAddressBook.length > 0) && filteredAddressBook.map((address: ICustomerAddress) => (
                            <>
                                <div data-test="address-item" key={address.id}>
                                    <AddressBookAddress
                                        address={address}
                                        selectedAddress={selectedAddress}
                                        setSelectedAddress={setSelectedAddress}
                                        preselectAddress={preselectAddress}
                                    />
                                </div>
                            </>
                        ))}
                        {(filteredAddressBook.length === 0) && (
                            <div className={fStyles.formField}>
                                <h3>{t('No addresses found')}</h3>
                            </div>
                        )}
                    </div>
                    <div className={classes(styles.addressButtons, fStyles.formField)}>
                        <button
                            className={fStyles.checkoutButton}
                            type="button"
                            onClick={handleShipToAddress}
                            disabled={selectedAddress === null}
                            data-test="ship-to-this-address"
                        >
                            {t('Ship To This Address')}
                        </button>
                        <button
                            className={fStyles.checkoutButton}
                            type="button"
                            onClick={() => {
                                resetAddress();
                                setShowAddressForm(true);
                            }}
                        >
                            {t('Add Address')}
                        </button>
                    </div>
                </form>
            )}
            {showAddressForm && (
                <AddressForm
                    address={address}
                    name={name}
                    handleSubmit={handleSubmit}
                    onChange={onChange}
                    loading={loading}
                    setAddress={setAddress}
                    allowSave={allowSave}
                />
            )}
        </>
    );
};
