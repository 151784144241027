import React, { useEffect } from 'react';
import {
    IAvailablePaymentMethod,
} from 'ui/page/checkout-page/checkout-state';
import { IOnVariablesChange } from '../payment-options';
import { PaymentInfo } from '../payment-method/payment-info';
import { CreditCardVault } from '../payment-options/buttons-content/credit-card-vault';
import optionsStyle from '../payment-options/style.css';

export const RepayCCVault = (
    {
        onVariablesChange,
        selectedMethod,
    }: { onVariablesChange: IOnVariablesChange; selectedMethod: IAvailablePaymentMethod },
) => {
    useEffect(() => {
        onVariablesChange({
            paymentMethodCode: selectedMethod?.code ?? '',
        });
    }, [selectedMethod]);

    if (!selectedMethod || !selectedMethod.code) {
        return (<PaymentInfo />);
    }

    const {
        title,
        code,
        details,
    } = selectedMethod;
    const selectedMethodInfo = title || code;

    return (
        <div className={optionsStyle.methodInfo}>
            <CreditCardVault title={selectedMethodInfo} code={code} details={details} />
        </div>
    );
};
