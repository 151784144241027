import React from 'react';
import { concat } from 'lodash';
import { Divider } from 'ui/component/checkout/components/divider';
import { generateKey } from 'ui/util/generate-key';
import { ProductInfoLine } from './product-info-line';
import { ProductInfoImgLine } from './product-info-img-line';
import styles from './style.css';

interface IProductEmbInfoProps {
    item: any;
}

export const ProductEmbInfo: React.FC<IProductEmbInfoProps> = ({ item }) => {
    const extractCustomizableOptions = (options, identifier) => {
        const filteredOptions = options.filter(option => option.product.sku === identifier)[0];
        if (filteredOptions?.customizable_options !== undefined) {
            return filteredOptions?.customizable_options.reduce((acc, item) => {
                acc[item.label] = item.values[0].value;
                return acc;
            }, {});
        }
        return null;
    };
    const embroideredText = extractCustomizableOptions(item.embroideryOptions, 'EMBT0000');
    const embroideredStandardLogos = extractCustomizableOptions(item.embroideryOptions, 'EMBL0000');
    const embroideredXLLogos = extractCustomizableOptions(item.embroideryOptions, 'EMBLXL0000');
    const embroideredLogos = concat(embroideredStandardLogos || [], embroideredXLLogos || []);
    const embroideredFlag = extractCustomizableOptions(item.embroideryOptions, 'FLAG20000');
    const specialInstruction = extractCustomizableOptions(item.embroideryOptions, 'emb-instructions');

    const logoLabels = ['Logo 1', 'Logo 2', 'Logo 3', 'Logo 4'];

    return (
        <div className={styles.productEmbroideryDetails}>
            {embroideredText && (
                <>
                    <div className={styles.productEmbroideryTextDetails}>
                        <ProductInfoLine label="Embroidery (line 1)" value={embroideredText['Embroidery (line 1)']} />
                        <ProductInfoLine label="Embroidery (line 2)" value={embroideredText['Embroidery (line 2)']} />
                        <ProductInfoLine label="Embroidery (line 3)" value={embroideredText['Embroidery (line 3)']} />
                        <ProductInfoLine label="Embroidery (Color)" value={embroideredText['Embroidery Color']} />
                        <ProductInfoLine
                            label="Embroidery (Placement)"
                            value={embroideredText['Embroidery Placement']}
                        />
                        <ProductInfoLine label="Embroidery (Style)" value={embroideredText['Embroidery Style']} />
                    </div>
                    <Divider />
                </>
            )}
            {embroideredLogos && (
                <div className={styles.productEmbroideryLogoDetails}>
                    {logoLabels.map(label => (
                        embroideredLogos.map((embroideredLogo, embroideredLogoIndex) => (
                            <React.Fragment key={generateKey([embroideredLogoIndex], label)}>
                                {embroideredLogo[`${label} (new)`] && (
                                    <>
                                        <ProductInfoImgLine
                                            className={styles.productEmbroideryLogoItem}
                                            label={label}
                                            value={embroideredLogo[`${label} Placement`]}
                                            images={[embroideredLogo[`${label} (new)`]]}
                                        />
                                        <Divider />
                                    </>
                                )}
                                {embroideredLogo[`${label} (onfile)`] && (
                                    <>
                                        <ProductInfoImgLine
                                            className={styles.productEmbroideryLogoItem}
                                            label={label}
                                            value={`${embroideredLogo[`${label} Placement`]}, ${embroideredLogo[`${label} (onfile)`]}`}
                                            imageUrl={embroideredLogo[`${label} Preview Path`]}
                                        />
                                        <Divider />
                                    </>
                                )}
                            </React.Fragment>
                        ))
                    ))}
                </div>
            )}
            {embroideredFlag && (
                <>
                    <div className={styles.productEmbroideryFlagDetails}>
                        <ProductInfoImgLine
                            label="Collar"
                            value="Flag"
                            images={[embroideredFlag['Embroidered Flags']]}
                        />
                    </div>
                    <Divider />
                </>
            )}
            {specialInstruction && (
                <>
                    <div className={styles.productEmbroiderySpecialInstructionDetails}>
                        <ProductInfoLine
                            label="Special Instruction"
                            value={specialInstruction['Special Instructions']}
                        />
                    </div>
                    <Divider />
                </>
            )}
        </div>
    );
};
