import React, { useState } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { SET_ORDER_COMMENT_ON_CART } from 'graphql/cart/payment-method/order-comment';
import { cartIdVar } from 'ui/page/checkout-page/checkout-state';
import { MethodHeader } from '../../payment/methods/method-header/method-header';
import styles from './style.css';

export const OrderComments: React.FC<{}> = () => {
    const t = usePhraseTranslater();
    const cartId = useReactiveVar(cartIdVar);
    const [orderComments, setOrderComments] = useState('');
    const [setOrderCommentOnCart, { loading, error }] = useMutation(
        SET_ORDER_COMMENT_ON_CART,
        {
            variables: {
                input: {
                    cart_id: cartId ?? '',
                    order_comment: orderComments ?? '',
                },
            },
        },
    );

    const handleOnBlur = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        e.preventDefault();
        if (cartId) {
            setOrderCommentOnCart();
        }
    };

    return (
        <MethodHeader methodName="Order Comments" identifier="order-comments">
            <div className={styles.orderCommentsContent}>
                <textarea
                    className={styles.orderComments}
                    name="order_comment"
                    id="order_comment"
                    rows={4}
                    maxLength={4096}
                    onChange={(e) => {
                        setOrderComments(e.target.value);
                    }}
                    onBlur={handleOnBlur}
                    value={orderComments}
                    disabled={loading}
                />
                {Boolean(orderComments.length) && (
                    <span className={styles.orderCommentsCount}>{t('%1 characters', orderComments.length)}</span>
                )}
            </div>
            {error && (
                <div className={styles.error}>
                    {t(error?.message)}
                </div>
            )}
        </MethodHeader>
    );
};
