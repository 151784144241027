import { configVar } from 'ui/page/checkout-page/checkout-state';

interface IAddressFormFields {
    firstname?: string;
    lastname?: string;
    company?: string;
    street?: string[];
    city?: string;
    region?: string;
    postcode?: string;
    countryCode?: string;
    telephone: any;
    country?: any;
}

export interface IAddressFormErrors {
    firstname?: string;
    lastname?: string;
    company?: string;
    street1?: string;
    city?: string;
    region?: string;
    postcode?: string;
    countryCode?: string;
    telephone?: string;
}

export function getFormValidationErrors() {
    return {
        countryCode: '',
        firstname: '',
        lastname: '',
        telephone: '',
        street1: '',
        city: '',
        region: '',
        postcode: '',
    };
}

export function validateAddressForm(formFields: IAddressFormFields) {
    const config = configVar();
    let formIsValid = true;
    const formValidationErrors = getFormValidationErrors() as IAddressFormErrors;
    if (!formFields.countryCode) {
        formIsValid = false;
        formValidationErrors.countryCode = config?.chefworks_checkout_validation_country ?? '';
    }
    if (!formFields.lastname) {
        formIsValid = false;
        formValidationErrors.lastname = config?.chefworks_checkout_validation_lastname ?? '';
    }
    if (!formFields.firstname) {
        formIsValid = false;
        formValidationErrors.firstname = config?.chefworks_checkout_validation_firstname ?? '';
    }
    if (!formFields.street || !formFields.street.length) {
        formIsValid = false;
        formValidationErrors.street1 = config?.chefworks_checkout_validation_street ?? '';
    }
    if (!formFields.city) {
        formIsValid = false;
        formValidationErrors.city = config?.chefworks_checkout_validation_city ?? '';
    }
    if (!formFields.region) {
        formIsValid = false;
        formValidationErrors.region = config?.chefworks_checkout_validation_region ?? '';
    }
    if (!formFields.postcode) {
        formIsValid = false;
        formValidationErrors.postcode = config?.chefworks_checkout_validation_postcode ?? '';
    }
    if (!formFields.telephone ||
        (typeof formFields.telephone !== 'undefined' && !formFields.telephone.match(/^\+(?:[0-9]?){6,14}[0-9]$/))
    ) {
        formIsValid = false;
        formValidationErrors.telephone = config?.chefworks_checkout_validation_telephone ?? '';
    }
    return { formIsValid, formValidationErrors };
}
