import React, { useEffect, useState } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { Card } from 'ui/component/checkout/components/card';
import fStyles from 'ui/component/checkout/styles/form-style.css';
import { SET_SHIPPING_METHOD } from 'graphql/cart/shipping-method';
import {
    cartAvailableShippingMethodsVar,
    cartIdVar,
    cartSelectedShippingMethodVar,
    cartVar,
    getInitialAddressState,
    isShippingMethodSetVar,
    stepsCompletedVar,
    showVerifyAddressVar,
    validatedAddressVar,
    originalAddressVar,
    shouldValidateAddressVar,
} from 'ui/page/checkout-page/checkout-state';
import { IShippingMethodMethod, ShippingMethod } from './shipping-method';

interface IUpdateShippingMethod {
    completeStep: boolean;
}

export const ShippingMethodForm = () => {
    const t = usePhraseTranslater();
    const cartId = useReactiveVar(cartIdVar);
    const stepsCompleted = useReactiveVar(stepsCompletedVar);
    const availableMethods = useReactiveVar(cartAvailableShippingMethodsVar);
    const cartShippingMethod: string|null = useReactiveVar(cartSelectedShippingMethodVar);
    const shouldValidateAddress = useReactiveVar(shouldValidateAddressVar);
    const [selectedMethod, setSelectedMethod] = useState<string|null>(cartShippingMethod);
    const [saveMethod, setSaveMethod] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [saveShippingMethod, { loading, error }] = useMutation(SET_SHIPPING_METHOD);

    const setShippingStepComplete = () => {
        stepsCompletedVar({ ...stepsCompleted, shipping: true });
        localStorage.setItem('shippingStepSaved', '1');
    };

    const setShippingStepIncomplete = () => {
        stepsCompletedVar({ ...stepsCompleted, shipping: false });
        localStorage.setItem('shippingStepSaved', '0');
    };

    const updateShippingMethod = ({ completeStep }: IUpdateShippingMethod) => {
        if (!availableMethods) {
            return;
        }
        const carrierCode = availableMethods.filter(
            method => method.method_code === selectedMethod,
        ).map(method => method.carrier_code)[0];
        if (carrierCode) {
            saveShippingMethod({
                variables: {
                    cartId,
                    methodCode: selectedMethod,
                    carrierCode: availableMethods.filter(
                        method => method.method_code === selectedMethod,
                    ).map(method => method.carrier_code)[0],
                    validateAddress: shouldValidateAddress,
                },
                onError: () => {
                    setShippingStepIncomplete();
                },
                onCompleted: async (data) => {
                    shouldValidateAddressVar(false);
                    setSaveMethod(false);
                    isShippingMethodSetVar(true);
                    cartVar(data.setShippingMethodsOnCart.cart);
                    if (data.setShippingMethodsOnCart.valid_address) {
                        validatedAddressVar(getInitialAddressState(data.setShippingMethodsOnCart.valid_address));
                        originalAddressVar(getInitialAddressState(data.setShippingMethodsOnCart.original_address));
                        showVerifyAddressVar(true);
                    } else if (completeStep) {
                        setShippingStepComplete();
                    }
                },
            });
        }
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShippingStepIncomplete();
        setSelectedMethod(event.target.value);
        setShowMore(false);
        setSaveMethod(true);
    };
    const handleSave = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setShippingStepIncomplete();
        updateShippingMethod({ completeStep: true });
    };
    const getShippingMethodByCode = (code: string): IShippingMethodMethod | null => {
        if (!code || !availableMethods) return null;
        const method = availableMethods.filter(
            method => method.method_code === code,
        );
        return method[0] || null;
    };
    const showMoreMethods = !selectedMethod || (selectedMethod && showMore) || false;
    const transitNote = t('Note: Transit time does not include fulfillment time.');
    useEffect(() => {
        if (saveMethod) {
            setShippingStepIncomplete();
            updateShippingMethod({ completeStep: false });
        }
    }, [selectedMethod]);

    useEffect(() => {
        setSelectedMethod(cartShippingMethod ?? (availableMethods?.[0]?.method_code || ''));
    }, [availableMethods, cartShippingMethod]);

    return (
        <form id="shipping-method-form">
            {!showMoreMethods && (
                <>
                    <Card title="Shipping Methods" identifier="shipping-methods" note={transitNote}>
                        <ShippingMethod
                            method={getShippingMethodByCode(selectedMethod)}
                            handleClick={onChange}
                            checked
                        />
                    </Card>
                    {availableMethods && (
                        <div data-test="more-shipping-methods" className={classes(fStyles.formField, fStyles.inline, fStyles.center)}>
                            <button
                                className={classes(fStyles.buttonLink)}
                                type="button"
                                onClick={() => setShowMore(true)}
                            >
                                {t('More Shipping Methods')}
                            </button>
                        </div>
                    )}
                </>
            )}
            {showMoreMethods && (
                <>
                    <Card title="Shipping Methods" identifier="shipping-methods" note={transitNote}>
                        {availableMethods && availableMethods.map(method => (
                            <ShippingMethod
                                key={method.method_code}
                                method={method}
                                handleClick={onChange}
                                checked={method.method_code === selectedMethod}
                            />
                        ))}
                    </Card>
                    {selectedMethod && (
                        <div className={classes(fStyles.formField, fStyles.inline, fStyles.center)}>
                            <button
                                className={classes(fStyles.buttonLink)}
                                type="button"
                                onClick={() => setShowMore(false)}
                            >
                                {t('Less Shipping Methods')}
                            </button>
                        </div>
                    )}
                    {error && <span className="error">{error}</span>}
                </>
            )}
            <div className={classes(fStyles.formField, fStyles.actionButton)}>
                <button
                    className={fStyles.checkoutButton}
                    type="submit"
                    onClick={handleSave}
                    disabled={loading}
                    data-test="shipping-submit"
                >
                    {t('Save And Continue')}
                </button>
            </div>
            {error && <div className={fStyles.error}>{error.message}</div>}
        </form>
    );
};
