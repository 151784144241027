import React from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import {
    cartIdVar,
    cartVar,
    configVar,
    customerVar,
} from 'ui/page/checkout-page/checkout-state';
import { GET_CART } from 'graphql/cart/cart';
import { useCheckoutLoader } from 'ui/page/checkout-page/checkout-loader';
import { CouponGiftCard } from './methods/coupon-gift-card';
import { Rewards } from './methods/rewards/rewards';
import { PaymentOptions } from './methods/payment-options';
import { Summary } from './summary';

export const PaymentForm = ({ setBeforePlaceOrder }) => {
    const cartId = useReactiveVar(cartIdVar);
    const { setCartLoading } = useCheckoutLoader();
    // fetchPolicy: "no-cache", inorder to get the updated cart after performing any mutation
    const [getCart] = useLazyQuery(GET_CART, {
        variables: {
            cartId,
        },
        fetchPolicy: 'no-cache',
        onError: () => {
            // prevents console log
        },
        onCompleted: (data) => {
            if (data.cart) {
                cartVar(data.cart);
            }
        },
    });

    const updateCart = async () => {
        setCartLoading(true);
        await getCart();
        setCartLoading(false);
    };

    const config = useReactiveVar(configVar);
    const customer = useReactiveVar(customerVar);
    const isPortalCustomer = (): boolean => config?.is_portal && customer?.portal?.code;
    return (
        <>
            <Rewards isPortalCustomer={() => isPortalCustomer()} updateCart={updateCart} config={config} />
            <CouponGiftCard updateCart={updateCart} />
            <Summary />
            <PaymentOptions setBeforePlaceOrder={setBeforePlaceOrder} />
        </>
    );
};
