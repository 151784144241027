import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

interface ICardProps {
    title?: string;
    note?: string;
    className?: string;
    identifier: string;
}

export const Card: React.FC<ICardProps> = ({
    title = '',
    note = '',
    className = '',
    children,
    identifier,
}) => {
    const t = usePhraseTranslater();
    const isShowHeader = Boolean(title || note);
    return (
        <div data-test={identifier} className={classes(styles.card, className)}>
            {
                isShowHeader && (
                    <div className={styles.header}>
                        {title && <div className={styles.title}>{t(title)}</div>}
                        {note && <div className={styles.note}>{t(note)}</div>}
                    </div>
                )}
            {children}
        </div>
    );
};
