import React from 'react';
import { useMutation } from '@apollo/client';
import { REMOVE_FROM_CART_MUTATION } from 'graphql/cart/cart';
import { cartIdVar, cartVar } from 'ui/page/checkout-page/checkout-state';
import { StandardLoadingImage } from 'ui/component/loading-image';
import styles from './style.css';

interface IDeleteProp {
    id: number;
    handleClose: () => void;
}

export const Delete: React.FC<IDeleteProp> = ({ id, handleClose }) => {
    const [removeFromCart, { loading, error }] = useMutation(REMOVE_FROM_CART_MUTATION);
    const handleRemove = async () => {
        const response = await removeFromCart({
            variables: {
                input: {
                    cart_id: localStorage.getItem('cartId'),
                    cart_item_id: id,
                },
            },
        });
        // TODO: Add loading state
        const { data } = response;
        if (data?.removeItemFromCart.cart.id) {
            localStorage.setItem('cartId', data.removeItemFromCart.cart.id);
            cartIdVar(data.removeItemFromCart.cart.id);
            cartVar(data.removeItemFromCart.cart);
            handleClose();
        }
    };
    return (
        <>
            {loading && (<div className={styles.loader}><StandardLoadingImage /></div>)}
            <div className={styles.deleteWindow}>
                {error && <div className="error">{error.message}</div>}
                <div className={styles.title}>
                    <i className="fa-solid fa-circle-exclamation" />
                    <span>Delete Garment</span>
                </div>
                <div className={styles.message}>
                    <span>Are you sure you want to delete this garment?</span>
                </div>
                <div className={styles.actions}>
                    <button type="button" className={styles.deleteNo} onClick={handleClose}>
                        Cancel
                    </button>
                    <button type="button" className={styles.deleteYes} onClick={handleRemove}>
                        Yes, Delete
                    </button>
                </div>
            </div>
        </>
    );
};
